<template>
  <div>
    <div class="sectionButtons-actions">
      <CButton
        color="success"
        size="lg"
        class="sectionButtons-actionsItem"
        @click="openCardNewSurvey()"
        >{{ $t('new') }} {{ $t('survey') }}</CButton>
    </div>
    <CCard accent-color="primary">
      <CCardHeader color="white">
        <b class="text-dark">{{ tableTitle }}</b>
      </CCardHeader>
      <CCardBody>
        <!-- section search -->
        <BlockColumn>
          <BlockItem :size="'1-2'">
            <BlockInfoSelect
              title="Country:"
              :selected="selectedCountry || countryCodes[0]"
              :values="countryCodes"
              @change-value="changeCountry"
            />
          </BlockItem>
        </BlockColumn>

        <CDataTable
          class="mb-0 table-outline"
          hover
          :items="tableItems"
          :fields="tableFields"
          head-color="light"
          no-sorting
          :items-per-page="10"
          pagination
          :table-filter="{label: $t('table_filter')}"
          :items-per-page-select="{label: $t('items_page')}"
        >
          <template #interval_hours="{ item }">
            <td>
              {{ (item.interval_hours / 24).toFixed(0) }}
            </td>
          </template>
          <template #start_date="{ item }">
            <td>
              {{ moment(item.start_date).format("YYYY/MM/DD") }}
            </td>
          </template>
          <template #end_date="{ item }">
            <td>
              {{ moment(item.end_date).format("YYYY/MM/DD") }}
            </td>
          </template>
          <template #options="{ item }">
            <td class="text-right">
              <div class="buttons">
              <CButton size="sm" class="btn" @click="viewSurvey(item)">
                <CIcon name="cil-notes" />
              </CButton>
              <CButton size="sm" class="" @click="openCardEditSurvey(item)">
                <CIcon name="cil-pencil" />
              </CButton>
              <CButton size="sm" class="" @click="openCardDeleteSurvey(item)">
                <CIcon name="cil-trash" />
              </CButton>
              </div>
            </td>
          </template>
          <template #no-items-view>
            <div class="d-flex align-items-center justify-content-center my-3">
                <span class="mr-2" style="font-size: 30px; font-weight: 600; ">{{ $t('no_items') }}</span>
                <CIcon name="cil-ban" class="text-danger" style="width: 35px; height: 35px;" />
              </div>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
const moment = require("moment");
import BlockColumn from "./BlockColumn";
import BlockItem from "./BlockItem";
import BlockInfoSelect from "./BlockInfoSelect";
export default {
  name: "ListSurvey",
  components: {
    BlockColumn,
    BlockItem,
    BlockInfoSelect,
  },
  props: {
    tableItems: Array,
    countryCodes: Array,
    selectedCountry: String,
  },
  data() {
    return {
      layout: "list",
      tableTitle: this.$i18n.t("surveys"),
      tableFields: [
        { key: "title", label: this.$t('title') },
        { key: "question_1", label: this.$t('question') + ' ' + 1 },
        { key: "question_2", label: this.$t('question') + ' ' + 2  },
        { key: "question_3", label: this.$t('question') + ' ' + 3 },
        { key: "start_date", label: this.$t('start') + ' ' + this.$t('date') + ' ' + 1 },
        { key: "end_date", label: this.$t('end') + ' ' + this.$t('date') + 1 },
        { key: "interval_hours", label: this.$t('interval') + ' ' + '(' + this.$t('start') + ')' },
        { key: "country", label: this.$t('country') },
        {
          key: "options",
          label: this.$t('start'),
          sorter: false,
          filter: false,
        },
      ],

      listStatus: [],
    };
  },
  async created() {
    const self = this;
    self.listStatus = [
      { _key: "0", value: "All status" },
      { _key: "1", value: "Approved" },
      { _key: "2", value: "Pending" },
    ];
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    viewSurvey: function (item) {
      this.$router.push(`/surveys/${item._id}/answers`)
    },
    changeCountry(country) {
      this.$emit("survey-countries", country);
    },
    openCardEditSurvey(survey) {
      this.$emit("survey-edit", {
        surveySelected: survey
      });
    },
    openCardNewSurvey(survey) {
      this.$emit("survey-new", {});
    },
    openCardDeleteSurvey(survey) {
      this.$emit("survey-delete", { surveySelected: survey });
    },
  },
};
</script>

<style lang="scss" scoped>
.sectionButtons-actions {
  padding: 1em 0 2em 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sectionButtons-actionsItem {
  margin-left: 1em;
  font-size: 14px;
}

.buttons {
  display: flex;
}
</style>